<template>
<div>
    <!-- navbar -->
    <Navbar/>
    <Spinner/>
    <div id="layoutSidenav">
        <Menu />
        <div id="layoutSidenav_content">
            <main>
            <!-- header -->
                 <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="fas fa-envelope"></i></div>
                                            Notificaciones
                                        </h1>
                                        <div class="page-header-subtitle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
            <!-- fin header -->
            <!-- body -->
                <div class="container-xl px-4 mt-n10">
                        <!-- Wizard card navigation-->
                        <div class="card">
                            <div class="card-header border-bottom">
                                <!-- Wizard navigation-->
                                <div class="nav nav-pills nav-justified flex-column flex-xl-row nav-wizard" id="cardTab" role="tablist">
                                    <!-- Wizard navigation item 1-->
                                    <CardSinButton title="Notificaciones existentes en el sistema" active="active" subTitle="" id="ingresados" :numberCard="this.listaMensajes.mensajesTotal" /> 
                                </div>
                            </div>
                            <div class="card-body mt-0">
                                <div class="tab-content" id="cardTabContent"  style="min-height: 400px !important;overflow-y: auto;">
                                    <div v-if="this.listaMensajes != null">
                                        <Mensaje v-for="(dato) in this.listaMensajes.notificaciones" :key="dato.id" v-if="dato != null" active="active" :infoMensaje="dato"/>
                                    </div>
                                    <div style="text-align: center;color:rgb(244, 161, 0)" v-else>No hay notificaciones.</div>
                                </div>
                                <div class="row mt-5" v-if="this.listaMensajes != null && this.listaMensajes.paginas != 0">
                                    <div class="col-md-12">
                                         <ul class="pagination justify-content-center">
                                            <li class="page-item me-2" v-if="this.listaMensajes.paginaSeleccionada != 1">
                                                <a style="cursor: pointer !important;" class="page-link" @click="previusPag(listaMensajes)" tabindex="-1" aria-disabled="true"> 
                                                    <i class="fas fa-angle-left"></i> Anterior
                                                </a>
                                            </li>
                                            <li class="page-item"><a class="page-link active-pag">{{this.listaMensajes.paginaSeleccionada}}</a></li>
                                            <li class="page-item ms-2" >
                                                <a v-if="this.listaMensajes.paginaSeleccionada != this.listaMensajes.paginas" style="cursor: pointer !important;" class="page-link" @click="nextPag(listaMensajes)">
                                                    Siguiente <i class="fas fa-angle-right"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            <!-- fin body -->
            </main>
        </div>
    </div>

</div>
</template>

<style>

.card-body {
    margin-top: 30px;
}

.page-link{
    color :#1f3c7b !important;
}

.active-pag{
     background-color :#4165b3 !important;
     color :white !important;
}

</style>

<script>
import router from '@/router/index.js' // Vue router instance
import Spinner from '../../components/spinner'
import Vue from 'vue'
import Navbar from '../../components/home/navbar/navbar'
import Menu from '../../components/home/menu'
import CardSinButton from '../../components/home/step/util/cardSinButton'
import Mensaje from '../../components/home/step/mensajes/mensaje'

export default {
    name: "Home",
    components: {
        Spinner,
        Navbar,
        Menu,
        CardSinButton,
        Mensaje,
    },
    mounted: function () {
        this.addMenu();
        this.$store.commit("mensajes/SET_LISTAMENSAJES", null);
        this.findMensajes(1);
    },
    methods: {
        previusPag(objMsg){
            this.findMensajes(parseInt(objMsg.paginaSeleccionada) - 1)
        },
        nextPag(objMsg){
            this.findMensajes(parseInt(objMsg.paginaSeleccionada) + 1)
        },
        addMenu(){
            document.body.classList.remove("sidenav-toggled");
        }, 
        async findMensajes(pag) {
            await this.$store.dispatch("mensajes/getMensajesByUsuario",pag)
        }
    },
    computed: {
        listaMensajes: {
            get() {
                return this.$store.state.mensajes.listaMensajes;
            }
        },
        errorListaTramite: {
            get() {
                return this.$store.state.mensajes.errorListaTramite;
            }
        },        
        cantidadMensajes: {
            get() {
                return this.$store.state.mensajes.cantidadMensajes;
            }
        },
    },
};
</script>
